<template>
  <div id="page-scroll" class="r-cover overflow-auto">
    <VueAnnouncer />

    <Header />
    <main class="r-cover">
      <div
        v-if="menuState"
        class="menu-overlay a-cover inset-0 z-30 bg-black opacity-50"
        @click="toggleMenu"
      />

      <div class="r-cover" :inert="menuState">
        <router-view />
      </div>

      <vue-progress-bar />

      <!-- <Cookies v-if="!loading" /> -->

      <Transition name="fade">
        <Loading v-if="loading" />
      </Transition>

      <IePrompt />
    </main>
  </div>
</template>

<script>
// https://github.com/WICG/inert
import 'wicg-inert'

import { mapState, mapGetters } from 'vuex'
import Header from '@/components/layout/Header.vue'
import Loading from '@/components/utility/Loading.vue'
import IePrompt from '@/components/utility/IePrompt.vue'
import Cookies from '@/components/utility/Cookies.vue'

export default {
  name: 'App',

  components: {
    Header,
    Loading,
    IePrompt,
    Cookies
  },

  computed: {
    ...mapState([ 'touch', 'browser', 'mobile', 'loading', 'environment', 'searchResults' ]),
    ...mapGetters([ 'posts' ]),

    environment() {
      return process.env.VUE_APP_ENV
    },
    isMobile() {
      return !!(this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md')
    },
    menuState() {
      return this.$store.state.showMenu
    },
    metaTitle() {
      const fullPath = this.$route.fullPath
      const splitPath = fullPath.split('/')
      const firstTitle = splitPath[2] ? `${this.formatTitle(splitPath[2])} | ` : ''
      const routeMeta = this.$route.meta.title
      const secondTitle = `${this.formatTitle(splitPath[1])}`
      return routeMeta || (firstTitle + secondTitle)
    }
  },

  watch: {
    $route() {
      document.title = `${this.htmlDecode(this.metaTitle)} | UWE Bristol Virtual tour`
      this.$announcer.set(this.htmlDecode(this.metaTitle))
      document.getElementById('page-scroll').scrollTop = 0
      document.body.scrollTop = 0
    },
    $mq: {
      immediate: true,
      handler() {
        const isMobile = this.$mq === 'sm' || this.$mq === 'md' || this.$mq === 'lg'
        this.$store.commit('isMobile', isMobile)
      }
    }
  },

  created() {
    this.$store.commit('environment', this.environment)

    this.$Progress.start()

    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },

  mounted() {
    this.$store.commit('browser', this.$browser.meta.name)
    window.addEventListener('touchstart', this.isTouch)
  },

  methods: {
    isTouch() {
      this.$store.commit('isTouch')
      document.body.setAttribute('id', 'touch')
      window.removeEventListener('touchstart', this.isTouch)
    },
    toggleMenu() {
      if (this.menuState) {
        this.$store.commit('toggleMenu', false)
      } else {
        this.$store.commit('toggleMenu', true)
      }
    },
    formatTitle(string) {
      const a = string.charAt(0).toUpperCase() + string.slice(1)
      const b = a.replace('-', ' ')
      return b
    }
  }
}
</script>
