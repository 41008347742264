<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 16.536 16.532"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="linkedin-in" d="M3.7,48.532H.273V37.495H3.7ZM1.985,35.989a1.995,1.995,0,1,1,1.985-2A2,2,0,0,1,1.985,35.989ZM16.532,48.532H13.112V43.159c0-1.281-.026-2.923-1.782-2.923-1.782,0-2.055,1.391-2.055,2.83v5.465H5.849V37.495H9.137V39h.048a3.6,3.6,0,0,1,3.244-1.782c3.469,0,4.107,2.284,4.107,5.251v6.063Z" transform="translate(0 -32)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
