import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'

import { gsap } from 'gsap'
import { browser, htmlDecode, utmHref } from './mixins'

// Fetch polyfill https://github.com/github/fetch
import 'whatwg-fetch'
// https://www.npmjs.com/package/vue3-mq
import VueMq from 'vue3-mq'
// https://github.com/aacassandra/vue3-progressbar
import VueProgressBar from '@aacassandra/vue3-progressbar'
// https://github.com/vue-a11y/vue-announcer/tree/next
import VueAnnouncer from '@vue-a11y/announcer'
import '@vue-a11y/announcer/dist/style.css'

const app = createApp(App)

gsap.config({
  nullTargetWarn: false
})

app.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1600
  }
})

app.use(browser)
app.use(VueAnnouncer)

app.use(VueProgressBar, {
  color: 'rgb(84, 56, 140)',
  failedColor: 'red',
  height: '4px'
})

app.mixin(htmlDecode)
app.mixin(utmHref)

app.config.devtools = true

app.use(router).use(store).mount('#app')
