<template>
  <transition
    name="cookie-consent-transition"
  >
    <div
      v-if="show"
      class="cookie-consent"
      role="dialog"
    >
      <slot
        name="message"
      >
        <span
          class="cookie-consent__message"
        >
          {{ message }}
          <slot
            name="link"
          >
            <a
              v-bind="{ href, target, rel }"
              class="cookie-consent__link"
              role="button"
            >
              {{ linkLabel }}
            </a>
          </slot>
        </span>
      </slot>
      <section
        @click="dismiss"
      >
        <slot
          name="button"
        >
          <button
            class="cookie-consent__compliance"
            type="button"
          >
            {{ buttonLabel }}
          </button>
        </slot>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookieConsent',
  props: {
    message: {
      type: String,
      default: 'This website uses cookies to ensure you get the best experience.'
    },
    linkLabel: {
      type: String,
      default: 'Learn more'
    },
    buttonLabel: {
      type: String,
      default: 'Got it!'
    },
    href: {
      type: String,
      default: 'http://cookiesandyou.com'
    },
    target: {
      type: String,
      default: '_blank'
    },
    rel: {
      type: String,
      default: 'noopener'
    },
    cookieName: {
      type: String,
      default: 'cookieconsent_status'
    },
    cookiePath: {
      type: String,
      default: '/'
    },
    cookieDomain: {
      type: String,
      default: ''
    },
    cookieExpiryDays: {
      type: Number,
      default: 365
    }
  },
  data() {
    return {
      show: undefined
    }
  },
  computed: {
    cookie() {
      return !this.getCookie(this.cookieName)
    }
  },
  beforeMount() {
    this.show = this.cookie
  },
  methods: {
    dismiss() {
      this.show = false
      this.setCookie(this.cookieName, 1, this.cookieExpiryDays, this.cookieDomain, this.cookiePath)
    },
    getCookie(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      return parts.length !== 2
        ? undefined
        : parts.pop().split(';').shift()
    },
    setCookie(name, value, expiryDays, domain, path) {
      const exdate = new Date()
      exdate.setDate(exdate.getDate() + (expiryDays || 365))
      const cookie = [
        `${name}=${value}`,
        `expires=${exdate.toUTCString()}`,
        `path=${(path || '/')}`
      ]
      if (domain) {
        cookie.push(`domain=${domain}`)
      }
      document.cookie = cookie.join(';')
    }
  }
}
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  padding: .5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background:  grey;
  text-align: center;
  color: #fff;
}

.cookie-consent__message,
.cookie-consent__compliance {
  margin: .5rem 1rem;
}

.cookie-consent__compliance {
  cursor: pointer;
  padding: 0.5rem 3rem;
  background: grey;
  border: 2px solid currentColor;
}

.cookie-consent__link {
  text-decoration: underline;
}

.cookie-consent-transition-leave-active {
  transition: transform .75s;
  transition-timing-function: cubic-bezier(.75,0,0,1);
}

.cookie-consent-transition-leave-to {
  transform: translateY(100%);
}
</style>
