const loadKrp = (to, from, next) => {
  // TODO: Extract to reusable helper for any script.
  const loadScript = (id, src, callback) => {
    const existingScript = document.getElementById(id)
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = src
      script.id = id
      document.body.appendChild(script)

      script.onload = () => {
        if (callback) {
          callback()
        }
      }
    }

    if (existingScript && callback) {
      callback()
    }
  }

  loadScript('krpano-script', './vendor/krpano.js', () => {
    next()
  })
}

export default loadKrp
