<template>
  <header class="header">
    <div class="flex justify-between absolute w-full md:px-10 h-17 md:h-auto">
      <router-link
        :class="[ isHome ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0' ]"
        class="logo block z-60 transform "
        to="/"
        title="Visit out website"
      >
        <img
          class="h-full md:h-auto"
          alt="UWE Logo"
          src="@/assets/logo.svg"
        >
      </router-link>
      <div class="flex z-60">
        <button
          v-if="!isHome"
          class="button-square w-17 md:w-auto text-center md:px-6 block bg-secondary cursor-pointer text-sm font-bold"
          @click="handleBackClick"
        >
          <div class="back-icon flex flex-col m-auto justify-between md:mb-3">
            <IconBase
              icon="IconChevronRight"
              size="33px"
              class="rotate-180" 
            />
          </div>
          <span class="hidden md:block">
            Back
          </span>
        </button>
        <button
          :class="[
            'menu-button button-square w-17 md:w-auto text-center md:px-6',
            'block bg-white cursor-pointer text-sm font-bold transform',
            isHome ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0'
          ]"
          ref="menuButton"
          title="Open menu"
          aria-label="Toggle the navigation menu"
          @click="toggleMenu"
        >
          <div class="menu-icon flex flex-col m-auto justify-between" :class="{'is-active': menuState}">
            <div class="menu-icon__item menu-icon__item--top" />
            <div class="menu-icon__item menu-icon__item--mid" />
            <div class="menu-icon__item menu-icon__item--btm" />
          </div>
          <span class="mt-3 hidden md:block">
            {{ menuState ? 'Close' : 'Menu' }}
          </span>
        </button>
      </div>
    </div>
    <Menu />
  </header>
</template>

<script>
import { gsap } from 'gsap'
import Menu from '@/components/layout/Menu.vue'
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'Header',

  components: {
    Menu,
    IconBase
  },

  watch: {
    $route() {
      this.$store.commit('toggleMenu', false)
      if (this.$route.name === 'home') {
        gsap.fromTo('.logo', { opacity: 0, y: -300 }, { opacity: 1, y: 0, delay: 0.1, duration: 1, ease: 'power1.inOut' })
        gsap.fromTo('.menu-button', { opacity: 0, y: -300 }, { opacity: 1, y: 0, delay: 0.3, duration: 1, ease: 'power1.inOut' })
      }
    }
  },

  computed: {
    menuState() {
      return this.$store.state.showMenu
    },
    isHome() {
      return this.$route.name === 'home'
    }
  },

  methods: {
    toggleMenu() {
      if (this.menuState) {
        this.$store.commit('toggleMenu', false)
      } else {
        this.$store.commit('toggleMenu', true)
      }
    },
    handleBackClick() {
      if (this.$route.meta.back) {
        this.$router.push({
          name: this.$route.meta.back,
          params: this.$route.params
        })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  transition: .8s background-color,
              .8s color;
  &:focus,
  &:hover {
    background-color: var(--color-black);
    color: var(--color-white);

    .menu-icon__item {
      background-color: var(--color-white);
    }
  }

  &:focus {
    box-shadow: none;
  }
}

  .back-icon,
  .menu-icon {
    position: relative;
    width: 33px;
    height: 32px;
  }

  .menu-icon__item {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color-black);
    border-radius: 1px;
    transition: all .3s linear;
    &.menu-icon__item--top {
      top: 50%;
      transform: translateY(-.8rem);
      .is-active & {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &.menu-icon__item--mid {
      top: 50%;
      margin-top: -1px;
      transition: background-color .3s linear;
      .is-active & {
        background-color: transparent;
      }
    }
    &.menu-icon__item--btm {
      top: 50%;
      transform: translateY(.8rem);
      .is-active & {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
</style>
