<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 31.793 33.028"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="cog-light" d="M48.268,27.4,46.1,26.142a13,13,0,0,0,0-3.256l2.171-1.254A1.594,1.594,0,0,0,49,19.8a16.486,16.486,0,0,0-3.831-6.632,1.6,1.6,0,0,0-1.96-.285l-2.168,1.252a12.927,12.927,0,0,0-2.819-1.63V10a1.6,1.6,0,0,0-1.231-1.556,16.63,16.63,0,0,0-7.642,0A1.6,1.6,0,0,0,28.12,10v2.5a12.925,12.925,0,0,0-2.819,1.63l-2.168-1.252a1.6,1.6,0,0,0-1.96.285A16.486,16.486,0,0,0,17.341,19.8a1.594,1.594,0,0,0,.736,1.831l2.171,1.254a13,13,0,0,0,0,3.256L18.077,27.4a1.594,1.594,0,0,0-.736,1.831,16.486,16.486,0,0,0,3.831,6.632,1.6,1.6,0,0,0,1.96.285L25.3,34.892a12.924,12.924,0,0,0,2.819,1.63v2.5a1.6,1.6,0,0,0,1.231,1.556,16.63,16.63,0,0,0,7.642,0,1.6,1.6,0,0,0,1.231-1.556v-2.5a12.928,12.928,0,0,0,2.819-1.63l2.168,1.252a1.6,1.6,0,0,0,1.96-.285A16.486,16.486,0,0,0,49,29.227a1.594,1.594,0,0,0-.736-1.831Zm-4.36,6.69L40.824,32.3a11.505,11.505,0,0,1-4.73,2.734V38.6a14.459,14.459,0,0,1-5.842,0V35.039a11.507,11.507,0,0,1-4.73-2.734l-3.084,1.781a14.383,14.383,0,0,1-2.925-5.058L22.6,27.247a11.6,11.6,0,0,1,0-5.466L19.512,20a14.393,14.393,0,0,1,2.925-5.059l3.084,1.782a11.489,11.489,0,0,1,4.73-2.735V10.428a14.436,14.436,0,0,1,5.842,0v3.561a11.489,11.489,0,0,1,4.73,2.735l3.084-1.782A14.392,14.392,0,0,1,46.833,20l-3.085,1.781a11.6,11.6,0,0,1,0,5.466l3.085,1.781a14.383,14.383,0,0,1-2.925,5.058ZM33.172,18.121a6.393,6.393,0,1,0,6.393,6.393A6.4,6.4,0,0,0,33.172,18.121Zm0,10.654a4.262,4.262,0,1,1,4.262-4.262A4.266,4.266,0,0,1,33.172,28.776Z" transform="translate(-17.276 -8)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
