<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 22.849 30.465"
    >
      <g
        :fill="color"
        class="fill-current"
      >
          <path id="map-marker-alt-light_1_" data-name="map-marker-alt-light (1)" d="M11.424,5.712a5.712,5.712,0,1,0,5.712,5.712A5.719,5.719,0,0,0,11.424,5.712Zm0,9.52a3.808,3.808,0,1,1,3.808-3.808A3.812,3.812,0,0,1,11.424,15.233ZM11.424,0A11.424,11.424,0,0,0,0,11.424c0,4.606,1.6,5.893,10.25,18.426a1.429,1.429,0,0,0,2.348,0c8.645-12.533,10.25-13.82,10.25-18.426A11.424,11.424,0,0,0,11.424,0Zm0,28.2C3.136,16.214,1.9,15.262,1.9,11.424A9.52,9.52,0,0,1,18.156,4.693a9.458,9.458,0,0,1,2.788,6.732C20.945,15.262,19.714,16.213,11.424,28.2Z"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
