<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 17.346 17.346"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="redo-light_2_" data-name="redo-light (2)" d="M24.926,8h-.35a.42.42,0,0,0-.42.42v3.869a8.673,8.673,0,1,0-1.672,10.822.42.42,0,0,0,.017-.61l-.247-.247a.419.419,0,0,0-.576-.016,7.484,7.484,0,1,1,1.65-8.992h-4a.42.42,0,0,0-.42.42v.35a.42.42,0,0,0,.42.42h5.6a.42.42,0,0,0,.42-.42v-5.6A.42.42,0,0,0,24.926,8Z" transform="translate(-8 -8)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
