<template>
  <div
    :inert="!menuState"
    :class="[
      'fixed h-full right-0 transform bg-accent-1 z-50 transition-all duration-200',
      'w-full p-12 md:w-auto pt-24 md:pt-32 md:pr-36 md:pl-12 md:pb-12 overflow-y-auto',
      { 'translate-x-full': !menuState }
    ]"
  >
    <!-- Route Links -->
    <ul class="mb-8 last:mb-0">
      <li
        v-for="(level1, i1) in navRoutes"
        :key="`level-1-${i1}`"
        class="mb-3 last:mb-0"
      >
        <div class="mb-1 last:mb-0">
          <router-link
            :to="level1.to"
            class="text-white text-xl font-semibold lg:hover:text-secondary focus:text-secondary transition-colors duration-300"
            active-class="text-secondary"
            :aria-label="level1.label"
            v-html="level1.text"
          />
        </div>
        <ul
          v-if="level1.children"
          class="mb-4 last:mb-0 pl-6"
        >
          <li
            v-for="(level2, i2) in level1.children"
            :key="`level-2-${i2}`"
            class="mb-1 last:mb-0"
          >
            <router-link
              :to="level2.to"
              class="text-white font-normal lg:hover:text-secondary focus:text-secondary transition-colors duration-300"
              active-class="text-secondary"
              :aria-label="level2.label"
              v-html="level2.text"
            />
          </li>
        </ul>
      </li>
    </ul>
    <!-- Main CTAs -->
    <div class="mb-4 last:mb-0">
      <Button
        v-for="(cta, index) in ctas"
        :key="index"
        is-external
        class="mb-3 at-btn"
        :button="{
          button_text: cta.text,
          button_link: utmHref(cta.url, 'tier-1-cta'),
          button_label: `Open cta link ${cta.text}`
        }"
      />
    </div>
    <!-- Page Links -->
    <div class="mb-4 last:mb-0">
      <router-link
        v-for="(link, i1) in pageLinks"
        :key="`page-link-${i1}`"
        :to="utmHref(`/page/${link.post_name}`, 'legal')"
        class="mb-2 last:mb-0 text-white lg:hover:text-secondary focus:text-secondary underline transition-colors duration-300"
        active-class="text-secondary"
        :aria-label="`Open ${link.post_title} page`"
        v-html="link.post_title"
      />
    </div>
    <!-- Social Media Links -->
    <ul
      v-if="socialLinks.length"
      class="mb-4 last:mb-0 flex"
    >
      <li
        v-for="(smLink, i1) in socialLinks"
        :key="`social-link-${i1}`"
        class="mr-4 last:mr-0"
      >
        <a
          :href="utmHref(smLink.href, 'social-link')"
          target="_blank"
          class="w-9 h-9 flex justify-center items-center rounded-full bg-secondary focus:bg-black lg:hover:bg-black focus:text-white lg:hover:text-white transition duration-300"
          :aria-label="smLink.label"
        >
          <IconBase
            :icon="smLink.icon"
            :size="smLink.size"
          />
        </a>
      </li>
    </ul>
    <div class="mt-6 mb-4 last:mb-0">
      <a
        :href="utmHref('https://www.revolutionviewing.com/', 'rv-acknowledgement')"
        target="_blank"
        class="text-white lg:hover:text-secondary focus:text-secondary underline transition-colors duration-300"
        aria-label="View Revolution Viewing website in a new tab"
        v-html="'Created by RV'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '@/components/buttons/Button.vue'
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'Menu',

  components: {
    Button,
    IconBase
  },

  computed: {
    ...mapGetters(['sections', 'options', 'tours']),
    menuState() {
      return this.$store.state.showMenu
    },
    contentSections() {
      return this.sections
    },
    navRoutes() {
      const { contentSections = [], tours = [] } = this
      // Home
      const homeRoute = {
        to: '/',
        text: 'Home',
        label: 'Return home'
      }
      // Explore
      const exploreRoutes = {
        to: '/explore',
        text: 'Explore for yourself',
        label: 'View all sections',
        children: contentSections.map((item) => ({
          to: `/explore/${item.slug}`,
          text: item.title.rendered,
          label: `View ${item.title.rendered} section`,
        }))
      }
      // Guided Tours
      const guidedTourRoutes = {
        to: '/guided-tours',
        text: 'Take a guided tour',
        label: 'View all guided tours',
        children: tours.map((item) => ({
          to: `/guided-tours/${item.slug}`,
          text: item.title.rendered,
          label: `View ${item.title.rendered} tour`,
        }))
      }
      // Return
      return [
        homeRoute,
        exploreRoutes,
        guidedTourRoutes,
      ]
    },
    ctas() {
      return this.options.ctas
    },
    pageLinks() {
      return this.options.page_links
    },
    socialLinks() {
      const { options = {} } = this
      const { social_links = {} } = options
      const linkArr = [
        {
          href: social_links.facebook,
          label: 'Open UWE facebook page in a new tab',
          icon: 'IconFacebook',
          size: '22px',
        },
        {
          href: social_links.twitter,
          label: 'Open UWE twitter profile in a new tab',
          icon: 'IconTwitter',
          size: '22px',
        },
        {
          href: social_links.youtube,
          label: 'Open UWE youtube channel in a new tab',
          icon: 'IconYoutube',
          size: '23px',
        },
        {
          href: social_links.instagram,
          label: 'Open UWE instagram page in a new tab',
          icon: 'IconInstagram',
          size: '17px',
        },
        {
          href: social_links.linkedin,
          label: 'Open UWE linkedin profile in a new tab',
          icon: 'IconLinkedin',
          size: '16px',
        },
      ]
      return linkArr.filter(({ href }) => href)
    }
  },

  watch: {
    menuState(state) {
      if (state && window.dataLayer) {
        window.dataLayer.push({ event: 'hamburger-menu-opened' })
      }
    }
  }
}
</script>
