<template>
  <span
    class="inline-block"
    :class="`text-${color}`"
  >
    <component
      :is="icon"
      :width="size"
      :height="size"
      :color="color"
    />
  </span>
</template>

<script>
import example from '@/components/icons/IconExample.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import IconSettings from '@/components/icons/IconSettings.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconRedo from '@/components/icons/IconRedo.vue'
import IconFacebook from '@/components/icons/IconFacebook.vue'
import IconTwitter from '@/components/icons/IconTwitter.vue'
import IconInstagram from '@/components/icons/IconInstagram.vue'
import IconLinkedin from '@/components/icons/IconLinkedin.vue'
import IconYoutube from '@/components/icons/IconYoutube.vue'
import IconPlay from '@/components/icons/IconPlay.vue'
import IconPause from'@/components/icons/IconPause.vue'

export default {
  name: 'IconBase',
  components: {
    example,
    IconChevronRight,
    IconSettings,
    IconMap,
    IconRedo,
    IconFacebook,
    IconTwitter,
    IconInstagram,
    IconLinkedin,
    IconYoutube,
    IconPlay,
    IconPause
  },

  props: {
    icon: {
      type: String,
      default: 'arrow'
    },
    size: {
      type: String,
      default: '22px'
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
