<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    width="35" height="35" fill="currentColor" class="bi bi-play" viewBox="0 0 32 32" id="IconChangeColor">
    <path d="M6 4l20 12-20 12z"></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>