// Utility function to Santize CTA URL
const pkg = require('@/../package.json')

const { utmCampaign } = pkg.config

export default {
  methods: {
    utmHref(inputHref, type) {
      const acceptedTypes = ['logo-click', 'tier-1-cta', 'tier-2-cta', 'social-link', 'legal', 'rv-acknowledgement', 'external-link-card']
      const isTypeValid = acceptedTypes.indexOf(type) !== -1
      if (!isTypeValid) { return '' }
      const utmQueries = `utm_source=revolution-viewing&utm_medium=referral&utm_campaign=${utmCampaign}&utm_content=${type}`
      if (!inputHref) { return '' }
      const inputHrefHasQuery = inputHref.indexOf('?') !== -1
      return `${inputHref}${inputHrefHasQuery ? '&' : '?'}${utmQueries}`
    }
  }
}
