<template>
  <div
    v-if="visible && browser === 'IE' && !loading"
    class="ie-prompt"
    role="dialog"
    aria-labelledby="ie-prompt__title"
    aria-describedby="ie-prompt__decription"
    @click="visible = false"
  >
    <div class="ie-prompt__dialogue">
      <div>
        <h2 id="ie-prompt__title">Hi it appears you are using Internet Explorer.</h2>
        <p id="ie-prompt__decription">In order to get the best experience from this website we highly recommend upgrading to a modern browser such as
          <a
            href="https://www.google.co.uk/chrome/"
            target="_blank"
          >
            Google Chrome
          </a>
          or
          <a
            href="https://www.mozilla.org/en-GB/firefox/new/"
            target="_blank"
          >
            Firefox
          </a>.
        </p>
        <button
          @click="visible = false"
        >
          Dismiss
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'IePrompt',

  data: () => ({
    visible: true
  }),

  computed: {
    ...mapState([ 'browser', 'loading' ])
  }
}
</script>

<style scoped>
.ie-prompt {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0 ,0 , 0.8);
}

.ie-prompt a {
  color: blue;
  text-decoration: underline;
}

.ie-prompt button {
  background-color: blue;
  border-radius: 5px;
  color: white;
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
}

.ie-prompt span {
  display: block;
}

.ie-prompt__dialogue {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 12rem;
  padding: 1rem;
  text-align: center;
  width: 32rem;
}
</style>
