/* eslint-disable no-inline-comments */
/* eslint-disable spaced-comment */
/* eslint-disable no-undef */
/* eslint-disable func-style */
const browser = {
  install: (app) => {
    const ua = window.navigator.userAgent
    const browserObj = {}

    // Firefox 1.0+
    browserObj.isFirefox = typeof InstallTrigger !== 'undefined'
    // Safari 3.0+
    browserObj.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window.safari || safari.pushNotification)
    // Internet Explorer 6-11
    browserObj.isIE = /*@cc_on!@ */ false || !!document.documentMode
    // Edge 20+
    browserObj.isEdge = !browserObj.isIE && !!window.StyleMedia
    // Chrome 1+
    browserObj.isChrome = !!window.chrome && !!window.chrome.webstore

    browserObj.meta = browserSpecs()
    browserObj.meta.ua = ua

    function browserSpecs() {
      // https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
      let tem

      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return { name: 'IE', version: tem[1] || '' }
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem !== null) { return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] } }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']

      if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1])
      return { name: M[0], version: M[1] }
    }

    app.config.globalProperties.$browser = browserObj
  }
}

export default browser

if (typeof window !== 'undefined' && window.Vue) {
  window.app.use(browser)
}
