<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 36 32"
  >
    <g
      :fill="color"
      class="fill-current"
    >
      <path
        d="M35.958 28.527l-11.020-28.026c-0.106-0.293-0.382-0.499-0.705-0.501h-0c-0.267 0.032-0.509 0.105-0.732 0.212l0.014-0.006c-0.404 0.142-0.614 0.515-0.391 1.149-1.359 0.701-2.936 1.27-4.592 1.628l-0.127 0.023c-0.047-0.246-0.194-0.451-0.396-0.574l-0.004-0.002v-0.431c0 0 0 0 0 0 0-1.104-0.895-2-2-2-0.002 0-0.004 0-0.006 0h-5.999c-0.373 0.010-0.717 0.123-1.007 0.312l0.008-0.005c-0.283-0.184-0.627-0.297-0.997-0.307l-0.003-0h-6.001c-1.104 0-2 0.895-2 2v0 28.002c0 1.104 0.895 2 2 2v0h6.001c0.373-0.010 0.717-0.123 1.007-0.312l-0.008 0.005c0.283 0.184 0.627 0.297 0.997 0.307l0.003 0h6.001c1.104 0 2-0.895 2-2v-22.573l9.463 24.070c0.107 0.294 0.385 0.501 0.71 0.501 0.003 0 0.006 0 0.008-0h-0c0.267-0.032 0.509-0.105 0.732-0.212l-0.014 0.006c0.402-0.14 0.614-0.515 0.391-1.149 1.359-0.701 2.936-1.27 4.592-1.628l0.127-0.023c0.228 0.65 0.634 0.79 1.023 0.653l0.472-0.165c0.294-0.107 0.501-0.384 0.501-0.709 0-0.088-0.015-0.173-0.043-0.251l0.002 0.005zM8 30h-6.001v-3.999h6.001zM8 24h-6.001v-15.999h6.001zM8 5.999h-6.001v-3.999h6.001zM16.001 29.999h-6.001v-3.998h6.001zM16.001 23.998h-6.001v-15.997h6.001zM16.001 5.997h-6.001v-3.998h6.001zM28.697 28.974l-9.709-24.294c1.797-0.419 3.37-0.987 4.853-1.711l-0.134 0.059 9.709 24.292c-1.797 0.419-3.37 0.986-4.853 1.711l0.134-0.059z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
