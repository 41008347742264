<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    width="35" height="35" fill="currentColor" class="bi bi-play" viewBox="0 0 32 32" id="IconChangeColor">
      <path d="M4 4h10v24h-10zM18 4h10v24h-10z"/> 
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>