import { createRouter, createWebHistory } from 'vue-router'
import store from './store.js'
import pkg from '../package.json'

//const { endpoint } = pkg.config
const endpoint = 'https://uwevt.kinsta.cloud/wp-json'

const routes = [
  {
    path: '/:catchAll(.*)',
    meta: {
      title: '404'
    },
    component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home'
    },
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
  },
  {
    path: '/explore',
    name: 'sections',
    meta: {
      isSections: true,
      back: 'home'
    },
    component: () => import(/* webpackChunkName: "sections" */ './views/Sections.vue')
  },
  {
    path: '/explore/:section',
    name: 'section',
    meta: {
      isSection: true,
      back: 'sections'
    },
    component: () => import(/* webpackChunkName: "section" */ './views/Section.vue')
  },
  {
    path: '/explore/subjects',
    name: 'subjects',
    meta: {
      back: 'sections'
    },
    component: () => import(/* webpackChunkName: "section" */ './views/Subjects.vue')
  },
  {
    path: '/explore/subject/:subject',
    name: 'subject',
    meta: {
      isSection: true,
      back: 'subjects'
    },
    component: () => import(/* webpackChunkName: "section" */ './views/Section.vue')
  },
  {
    path: '/explore/:section/:group/:content',
    name: 'group',
    meta: {
      isGroup: true,
      back: 'section'
    },
    component: () => import(/* webpackChunkName: "group" */ './views/Group.vue'),
    children: [
      {
        path: ':hotspot',
        name: 'hotspot',
        meta: {
          back: 'section'
        },
        component: () => import(/* webpackChunkName: "group" */ './views/Group.vue')
      }
    ]
  },
  {
    path: '/guided-tours',
    name: 'guided-tours',
    meta: {
      title: 'Guided Tours',
      isGuidedTours: true,
      back: 'home'
    },
    component: () => import(/* webpackChunkName: "guided-tours" */ './views/GuidedTours.vue')
  },
  {
    path: '/guided-tours/:tour',
    name: 'tour',
    meta: {
      isSection: true,
      back: 'guided-tours'
    },
    component: () => import(/* webpackChunkName: "section" */ './views/Section.vue')
  },
  {
    path: '/guided-tours/:tour/:content',
    name: 'tour-group',
    meta: {
      isGroup: true,
      back: 'tour'
    },
    component: () => import(/* webpackChunkName: "tour-group" */ './views/TourGroup.vue'),
    children: [
      {
        path: ':hotspot',
        name: 'tour-hotspot',
        meta: {
          back: 'tour'
        },
        component: () => import(/* webpackChunkName: "tour-group" */ './views/TourGroup.vue')
      }
    ]
  },
  {
    path: '/page/:page',
    name: 'page',
    meta: {
      back: 'home'
    },
    component: () => import(/* webpackChunkName: "page" */ './views/Page.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.posts.length) {
    Promise.all([
      fetch(`${endpoint}/rv/v1/basis?per_page=-1&orderby=menu_order`),
      fetch(`${endpoint}/acf/v3/options/site-options`)
    ])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(([res, options]) => {
        store.commit({
          type: 'populate',
          posts: res,
          options: options,
          error: null
        })
        store.commit('route', to)
        next()
        store.commit('isLoading', false)
      })
      .catch((err) => {
        store.commit({
          type: 'populate',
          error: err
        })
      })
  } else {
    store.commit('isLoading', true)
    next()
    store.commit('route', to)
    store.commit('isLoading', false)
  }
})

export default router
