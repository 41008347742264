<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 6.738 12.409"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path d="M12.312.3l-.2-.2a.334.334,0,0,0-.473,0L6.206,5.536.768.1A.334.334,0,0,0,.3.1L.1.3A.334.334,0,0,0,.1.768L5.967,6.64a.334.334,0,0,0,.473,0L12.309.768A.332.332,0,0,0,12.312.3Z" transform="translate(0 12.409) rotate(-90)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
