import Vuex from 'vuex'

const store = Vuex.createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    error: null,
    loading: true,
    environment: 'development',
    browser: '',
    touch: false,
    mobile: false,
    showMenu: false,
    route: '',
    slug: '',
    posts: [],
    options: [],
    activeHotspotContent: null,
    // Panorama
    panoAccessibilityControlsOpen: false,
    panoIsAutoRotating: false
  },

  getters: {
    posts: state => state.posts,
    options: state => state.options,
    sections: (state, getters) => getters.posts.filter(({ type }) => type === 'section').slice(0, 4),
    groups: (state, getters) => getters.posts.filter(({ type }) => type === 'group'),
    panoramas: (state, getters) => getters.posts.filter(({ type }) => type === 'panorama'),
    videos: (state, getters) => getters.posts.filter(({ type }) => type === 'video'),
    galleries: (state, getters) => getters.posts.filter(({ type }) => type === 'gallery'),
    articles: (state, getters) => getters.posts.filter(({ type }) => type === 'post'),
    subjects: (state, getters) => {
      const subjects = getters.posts.filter(({ type }) => type === 'subject')
      // Sort subjects alphabetically
      subjects.sort((a, b) => {
        const subjectA = a.title.rendered
        const subjectB = b.title.rendered
        return (subjectA < subjectB) ? -1 : (subjectA > subjectB) ? 1 : 0
      })
      return subjects
    },
    tours: (state, getters) => getters.posts.filter(({ type }) => type === 'tour'),
    homepageContent: state => state.posts.find(({ slug }) => slug === 'homepage'),
    //
    getGetterByType: (state, getters) => type => {
      const article = type === 'post' && getters.articles
      const panorama = type === 'panorama' && getters.panoramas
      const video = type === 'video' && getters.videos
      const gallery = type === 'video' && getters.videos
      return article || panorama || video || gallery || getters.posts
    },
    getSectionBySlug: (state, getters) => (section) => {
      const matchingSection = getters.sections.find(({ slug }) => slug === section)
      const matchingSubject = getters.subjects.find(({ slug }) => slug === section)
      return matchingSection || matchingSubject
    },
    getTourSectionBySlug: (state, getters) => (section) => {
      const tours = getters.tours.filter(({ type }) => type === 'tour')
      return tours.find(({ slug }) => slug === section)
    },
    getGroupsByID: (state, getters) => (groups) => {
      const allGroups = getters.groups.filter(({ type }) => type === 'group')
      const groupsWithOrder = []
      const filteredGroups = []
      // Find groups in all groups
      allGroups.forEach((item) => {
        groups.some((el) => {
          if (el.id === item.id) {
            groupsWithOrder.push({ group: item, order: el.order })
          }
        })
      })
      // Sort by menu order
      groupsWithOrder.sort((a, b) => {
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1
        return 0
      })
      // Delete menu order
      groupsWithOrder.forEach((item) => {
        delete item.order
        filteredGroups.push(item.group)
      })
      return filteredGroups
    },
    getGroupBySlug: (state, getters) => groupSlug => getters.groups.find(({ slug }) => slug === groupSlug),
    getPostBySlug: (state, getters) => (postSlug, type) => getters.getGetterByType(type).find(({ slug }) => slug === postSlug),
    getPanoChildren: state => (post) => {
      const pano = state.posts.find(({ slug }) => slug === post)
      return pano.children
    },
    getGuidedTours: (state) => {
      const tours = state.posts.filter(({ type }) => type === 'tour')

      if (tours.length > 3) {
        return tours.slice(0, 3)
      }

      return tours
    },
    getPageBySlug: state => page => state.posts.find(({ slug }) => slug === page),
  },

  mutations: {
    browser(state, browser) {
      state.browser = browser
    },
    environment(state, environment) {
      state.environment = environment
    },
    isLoading(state, loading) {
      state.loading = loading
    },
    isTouch(state) {
      state.touch = true
    },
    isMobile(state, mobile) {
      state.mobile = mobile
    },
    route(state, route) {
      const slug = route.path.split('/').pop() || '/'
      state.route = route
      state.slug = slug
    },
    populate(state, { posts, options, error } = {}) {
      state.error = error
      const data = posts.map((item) => {
        return {
          ...item,
          ...item.acf
        }
      })

      state.posts = [ ...data ]
      state.options = options.acf
    },
    toggleMenu(state, payload) {
      state.showMenu = payload
    },
    toggleHotspotContent(state, payload) {
      state.activeHotspotContent = payload
    },
    setPanoAutorotate(state, payload) {
      state.panoIsAutoRotating = payload
    },
    togglePanoAccessibilityControls(state) {
      state.panoAccessibilityControlsOpen = !state.panoAccessibilityControlsOpen
    }
  },

  actions: {}
})

export default store
