<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 17.028 17.024"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="instagram" d="M8.441,35.972a4.365,4.365,0,1,0,4.365,4.365A4.358,4.358,0,0,0,8.441,35.972Zm0,7.2a2.838,2.838,0,1,1,2.838-2.838A2.843,2.843,0,0,1,8.441,43.175ZM14,35.794a1.018,1.018,0,1,1-1.018-1.018A1.016,1.016,0,0,1,14,35.794Zm2.891,1.033a5.038,5.038,0,0,0-1.375-3.567,5.071,5.071,0,0,0-3.567-1.375c-1.406-.08-5.618-.08-7.024,0A5.064,5.064,0,0,0,1.36,33.256,5.055,5.055,0,0,0-.015,36.823c-.08,1.406-.08,5.618,0,7.024A5.038,5.038,0,0,0,1.36,47.414a5.078,5.078,0,0,0,3.567,1.375c1.406.08,5.618.08,7.024,0a5.038,5.038,0,0,0,3.567-1.375,5.071,5.071,0,0,0,1.375-3.567C16.973,42.441,16.973,38.232,16.893,36.827Zm-1.816,8.528a2.873,2.873,0,0,1-1.618,1.618c-1.121.444-3.78.342-5.018.342s-3.9.1-5.018-.342A2.873,2.873,0,0,1,1.8,45.355c-.444-1.121-.342-3.78-.342-5.018s-.1-3.9.342-5.018A2.873,2.873,0,0,1,3.423,33.7c1.121-.444,3.78-.342,5.018-.342s3.9-.1,5.018.342a2.873,2.873,0,0,1,1.618,1.618c.444,1.121.342,3.78.342,5.018S15.522,44.238,15.077,45.355Z" transform="translate(0.075 -31.825)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
