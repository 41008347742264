<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 11.294 21.904"
    >
      <g
        :fill="color"
        class="fill-current"
      >
          <path id="facebook-f" d="M9.232,3.636h2.062V.154a27.548,27.548,0,0,0-3-.154c-2.973,0-5.01,1.814-5.01,5.146V8.214H0v3.893H3.281v9.8H7.3v-9.8h3.149l.5-3.893H7.3V5.531C7.3,4.406,7.615,3.636,9.232,3.636Z"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
