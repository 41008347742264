<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 21.903 17.79"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="twitter" d="M19.652,52.516c.014.195.014.389.014.584A12.685,12.685,0,0,1,6.894,65.872,12.686,12.686,0,0,1,0,63.856a9.289,9.289,0,0,0,1.084.056,8.99,8.99,0,0,0,5.573-1.918,4.5,4.5,0,0,1-4.2-3.113,5.66,5.66,0,0,0,.848.069A4.748,4.748,0,0,0,4.489,58.8a4.49,4.49,0,0,1-3.6-4.406v-.056a4.521,4.521,0,0,0,2.029.57,4.5,4.5,0,0,1-1.39-6,12.76,12.76,0,0,0,9.256,4.7,5.068,5.068,0,0,1-.111-1.028A4.493,4.493,0,0,1,18.443,49.5a8.838,8.838,0,0,0,2.849-1.084,4.477,4.477,0,0,1-1.974,2.474,9,9,0,0,0,2.585-.695,9.65,9.65,0,0,1-2.251,2.321Z" transform="translate(0 -48.082)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
