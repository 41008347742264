<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 23.364 16.428"
    >
      <g
        :fill="color"
        class="fill-current"
      >
        <path id="youtube" d="M37.809,66.57a2.936,2.936,0,0,0-2.066-2.079C33.921,64,26.615,64,26.615,64s-7.306,0-9.128.491a2.936,2.936,0,0,0-2.066,2.079,33.054,33.054,0,0,0,0,11.32,2.892,2.892,0,0,0,2.066,2.046c1.822.491,9.128.491,9.128.491s7.306,0,9.128-.491a2.892,2.892,0,0,0,2.066-2.046,33.054,33.054,0,0,0,0-11.32ZM24.225,75.7V68.757l6.106,3.474Z" transform="translate(-14.933 -64)"/>
      </g>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
