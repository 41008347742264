<template>
  <a
    v-if="isExternal"
    class="button bg-secondary block px-5 py-3 flex justify-center min-w-60 text-base font-semibold"
    :href="button.button_link"
    target="_blank"
    :title="button.button_label"
    :aria-label="button.button_text"
  >
    <span class="z-10" v-html="button.button_text" />
    <IconBase
      icon="IconChevronRight"
      size="14px"
      :class="{'rotate-90 is-active': button.state_change}"
      class="relative flex items-center button__icon pl-3 z-10"
    />
  </a>
  <router-link
    v-else
    class="button block px-5 flex justify-center text-base font-semibold"
    :to="button.button_link"
    :title="button.button_label"
    :aria-label="button.button_label"
    @click="buttonClick"
  >
    <span class="z-10" v-html="button.button_text" />
    <IconBase
      icon="IconChevronRight"
      size="14px"
      :class="{'rotate-90 is-active': button.state_change}"
      class="relative flex items-center button__icon pl-3 z-10"
    />
  </router-link>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'

export default {
  name: 'Button',

  components: {
    IconBase
  },

  props: {
    isExternal: {
      type: Boolean,
      default: null
    },
    button: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    buttonClick() {
      this.$emit('buttonClick')
    }
  }
}
</script>

